import { useEffect, useState } from "react";
import AdminUserList from "./AdminUserList";

const AdminUsers = (props:{user:any|null})=>{

    return (
        <>
            <AdminUserList />
        </>
    );
}

export default AdminUsers;
